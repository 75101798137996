import React, {useEffect, useState} from "react";
import {CheckBox, CheckBoxOutlineBlank, ArrowDropDown, ChildCare} from "@mui/icons-material";
import * as yup from "yup";
import {UseFormRegister, FieldValues, Controller, Control} from "react-hook-form";
import {ClickAwayListener} from "@mui/base";
import {grey} from "@mui/material/colors";
import lo from "lodash";
import {
    Button as ButtonBase,
    ButtonProps as ButtonPropsBase,
    Autocomplete as AutocompleteBase,
    TextField as TextFieldBase,
    TextFieldProps as TextFieldPropsBase,
    Typography,
    Box,
    InputAdornment,
    Chip as ChipBase,
    ChipProps as ChipPropsBase,
    Checkbox,
    IconButton,
    useTheme,
    Theme,
    CircularProgress,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
    FormGroup,
    AutocompleteRenderGroupParams,
} from "@mui/material";

const OPTION_SEPARATOR="-_-";
export const ITEM_META="_meta";
const OPTIONS_SIZE={limit: 50, message: "Too many results. Please add more details to narrow responses."};
const DROPDOWN_BORDER_RADIUS="8px";

interface Register {
    register?:UseFormRegister<FieldValues>
}

export interface OptionHeader {
    key:string
    label:string
    width?:number
}

interface InputValidator {
    yup:(value:any) => Promise<yup.ValidationError|boolean>
    key:string
}

export interface Selector{
    label:string
    key:string
}

interface SelectorButtonProps {
    options: Selector[],
    onChange: (option:Selector) => void
    disabled?:boolean
}

interface EndAdornmentType{
    icon:any
    onClick: (args:React.MouseEvent|React.KeyboardEvent, value:any, selector?:Selector) => Promise<void>
}

interface AutocompleteOptions{
    options:string[]|any[]
    disabled?:boolean
    selector?:Selector[]
    multiple?:boolean
    endAdornment?:EndAdornmentType
    readOnly?:boolean
    helperText?:string
    optionsHeader?:OptionHeader[]
    inputValidator?: InputValidator[]
    selectAllLabel?:string
}

export interface Field{
    key:string
    label:string
    yup:yup.Schema
    type:"autocomplete"
    autocompleteOptions?:AutocompleteOptions
}

interface ButtonProps extends ButtonPropsBase {
    label?: string
}

type TextFieldProps=TextFieldPropsBase & Register;

interface ControlledField {
    field:Field
    control:Control<FieldValues>
    errors?:any,
    onItemSelection?: (data:any) => void;
    loading?:boolean
}

interface DropdownRowProps {
    width:number | undefined
    label:string,
    border:string
}

/**
 * Button
 * @param {ButtonProps} props
 * @return {React.ReactElement}
 */
function Button(props:ButtonProps):React.ReactElement {
    return <ButtonBase sx={{width: "100%"}} {...props}>{props.label}</ButtonBase>;
}

/**
 * Chip
 * @param {ChipPropsBase} ChipProps
 * @return {React.ReactElement}
 */
function Chip(props:ChipPropsBase):React.ReactElement {
    return <ChipBase {...props} />;
}

/**
 * TextField
 * @param {TextFieldProps} props
 * @return {React.ReactElement}
 */
function TextField(props:TextFieldProps):React.ReactElement {
    return <TextFieldBase {...props} sx={{width: "100%"}} />;
}

/**
 * SelectorButton
 * @param {props} SelectorButtonProps
 * @return {React.ReactElement}
 */
function SelectorButton(props:SelectorButtonProps):React.ReactElement {
    const {options, onChange} = props;
    const [selectorOpen, setSelectorOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [selectedOption, setSelectedOption] = React.useState(options[0]);

    /**
     * handleMenuItemClick
     * @param {option} Selector
     * @return void
     */
    const handleMenuItemClick = (option:Selector):void => {
        onChange(option);
        setSelectedOption(option);
        setSelectorOpen(false);
    };

    /**
     * handleToggle
     * @return void
     */
    const handleToggle = ():void => { setSelectorOpen((prevOpen) => !prevOpen); };

    /**
     * handleClose
     * @param {e} Event
     * @return void
     */
    const handleClose = (e: Event):void => {
        if (anchorRef.current && anchorRef.current.contains(e.target as HTMLElement)) return;
        setSelectorOpen(false);
    };

    return (
        <Box ref={anchorRef}>
            <ButtonBase
                disabled={props.disabled}
                variant="outlined"
                size="large"
                onClick={handleToggle}
                sx={{
                    height: "56px",
                    width: "100px",
                    borderColor: grey[400],
                    borderRight: "0px",
                    borderTopLeftRadius: DROPDOWN_BORDER_RADIUS,
                    borderBottomLeftRadius: DROPDOWN_BORDER_RADIUS,
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                    "&:hover": {borderRight: "0px", borderColor: grey[800]},
                }}
            >
                <Typography sx={{textTransform: "none", fontSize: "16px", color: props.disabled ? grey[500] : grey[700]}}>
                    {selectedOption.label}
                </Typography>
                <ArrowDropDown sx={{color: props.disabled? grey[400] : grey[600]}} />
            </ButtonBase>
            <Popper
                modifiers={[{name: "offset", options: {offset: [0, -20]}}]}
                sx={{zIndex: 1, width: "135px"}}
                open={selectorOpen}
                anchorEl={anchorRef.current}
                transition
                disablePortal
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem>
                                    {options.map((option:Selector) => (
                                        <MenuItem
                                            key={option.key}
                                            selected={option === selectedOption}
                                            onClick={() => handleMenuItemClick(option)}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
}

/**
 * DropdownRow
 * @param {props} DropdownRowProps
 * @return {React.ReactElement}
 */
function DropdownRow(props:DropdownRowProps):React.ReactElement {
    return (
        <Box
            component="span"
            sx={{
                width: `${props.width}%`,
                borderRight: props.border,
                padding: "0px 8px !important",
                margin: "4px 0px !important",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }}
        >
            {props.label}
        </Box>
    );
}

/**
 * ControlledAutocomplete
 * @param {ControlledField} props
 * @return {React.ReactElement}
 */
function ControlledAutocomplete(props:ControlledField): React.ReactElement|null {
    const [open, setOpen]=useState(false);
    const [checkAll, setCheckAll]=useState(false);
    const [selector, setSelector]=useState<Selector|undefined>(props.field.autocompleteOptions?.selector?.[0]);
    const [inputError, setInputError]=useState(false);
    const [internalHelperText, setInternalHelperText]=useState("");
    const [asyncTextFieldFocus, setAsyncTextFieldFocus]=useState(false);

    const loading = open && props.loading;
    const isThreshold=props.field.autocompleteOptions?.endAdornment!==undefined && props.field.autocompleteOptions && props.field.autocompleteOptions?.options.length>OPTIONS_SIZE.limit;
    const disableCloseOnSelect=props.field.autocompleteOptions?.multiple;
    const theme:Theme=useTheme();

    // NOTE: used for async autocomplete variant to reset options when dropdown not open
    useEffect(() => {
        if (!open && props.field.autocompleteOptions?.endAdornment && props.field.autocompleteOptions?.options.length!==0) props.field.autocompleteOptions.options=[]; // eslint-disable-line no-param-reassign
    }, [open, props.field.autocompleteOptions]);

    if (props.field.autocompleteOptions===undefined) return null;

    /**
     * renderGroup
     * @param {AutocompleteRenderGroupParams} params
     * @return {React.ReactElement}
     */
    const renderGroup=(params:AutocompleteRenderGroupParams):React.ReactElement => {
        const optionHeader = props.field.autocompleteOptions?.optionsHeader;
        return (
            <Box key={params.key}>
                {optionHeader&&(
                    <Box sx={{display: "flex", fontSize: "14px", fontWeight: "700", borderBottom: "1px solid #0000001F"}}>
                        {optionHeader?.map((header:any, i, arr) => (
                            <DropdownRow width={header.width} key={header.key} label={header.label} border={arr[i + 1] ? "1px solid #0000001F" : ""} />
                        ))}
                    </Box>
                )}
                {params.children}
            </Box>

        );
    };

    /**
     * renderOption
     * @param {any} renderOptionProps
     * @param {string | any} option
     * @return {React.ReactElement}
     */
    const renderOption=(renderOptionProps:any, option:any, checkBox:any):React.ReactElement => {
        let counter = 0;
        const optionHeader = props.field.autocompleteOptions?.optionsHeader;
        const selectAllLabel = props.field.autocompleteOptions?.selectAllLabel;
        const selectAllLabelStyle = {paddingLeft: "0", borderBottom: "1px solid #0000001F"};
        const {key, ...rest}=renderOptionProps;
        if (props.field.autocompleteOptions?.multiple) {
            return (
                <li key={key} style={selectAllLabel===option?selectAllLabelStyle:undefined} {...rest}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        style={{marginRight: 8}}
                        checked={checkBox.selected}
                    />
                    {option}
                </li>
            );
        }
        if (!props.field.autocompleteOptions?.optionsHeader) return <Box component="li" {...rest} key={key}>{option}</Box>;
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between !important",
                    padding: "0px !important",
                    fontSize: "14px",
                }}
                component="li"
                {...rest}
                key={key}
            >
                {optionHeader?.map((k, i, arr) => {
                    const optionKey = `${option[k.key]}-${counter += 1}`;
                    return (
                        <DropdownRow width={optionHeader?.[i].width} key={optionKey} label={option[k.key]} border={arr[i + 1] ? "1px solid #0000001F" : ""} />
                    );
                })}
            </Box>
        );
    };

    /**
     * renderInput
     * @param {TextFieldProps} params
     * @return {React.ReactElement}
     */
    const renderInput=(params:TextFieldProps):React.ReactElement => {
        let helperText=" ";
        let label=" ";
        if (props.errors[props.field.key]) helperText=props.errors[props.field.key]?.message;
        else if (isThreshold) helperText=OPTIONS_SIZE.message;
        else if (props.field.autocompleteOptions?.helperText) helperText=props.field.autocompleteOptions?.helperText;
        const endAdornment = props.field.autocompleteOptions?.endAdornment;

        if (props.field.autocompleteOptions?.selector) label = `Search ${selector?.label}`;
        else label = props.field.label;

        /**
         * onEndAdornmentClick
         * @return {void}
         */
        const onEndAdornmentClick=(args:React.MouseEvent|React.KeyboardEvent):void => {
            if (props.field.autocompleteOptions?.disabled) return;
            const valKey = props.field.autocompleteOptions?.selector ? selector?.key : props.field.key;
            const validator = props.field.autocompleteOptions?.inputValidator?.find((x) => x.key === valKey);
            if (validator?.yup) {
                validator.yup(params.inputProps?.value)
                    .then((result:boolean|yup.ValidationError) => {
                        if (result===true) {
                            setOpen(true);
                            setInputError(false);
                            endAdornment?.onClick(args, params.inputProps?.value, selector);
                            setInternalHelperText("");
                        } else {
                            setInputError(true);
                            setInternalHelperText((result as yup.ValidationError)?.errors[0]);
                        }
                    }).catch((_e) => console.log(_e));
            } else {
                console.log("No validator created for this input");
            }
        };

        /**
         * onEndAdornmentKeyDown
         * @param {React.KeyboardEvent} args
         */
        const onEndAdornmentKeyDown=async (args:React.KeyboardEvent):Promise<void> => {
            if (args.code==="Enter" || args.code==="NumpadEnter") {
                args.preventDefault(); // prevents form submission
                onEndAdornmentClick(args); // triggers search
            }
        };

        return (
            <ClickAwayListener onClickAway={():void => { if (!disableCloseOnSelect) setOpen(false); }}>
                <Box>
                    <TextField
                        color="secondary"
                        {...params}
                        inputProps={asyncTextFieldFocus ? params.inputProps : {...params.inputProps, value: ""}}
                        InputProps={{
                            ...params.InputProps,
                            ...props.field.autocompleteOptions?.endAdornment!==undefined && {onKeyDown: onEndAdornmentKeyDown},
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    <InputAdornment sx={{position: "absolute", right: "8px"}} position="end">
                                        {endAdornment
                                            ? <IconButton disabled={props.field.autocompleteOptions?.disabled} edge="end" onClick={onEndAdornmentClick} size="small">{endAdornment.icon}</IconButton>
                                            : params.InputProps?.endAdornment }
                                    </InputAdornment>
                                </>
                            ),
                        }}
                        helperText={internalHelperText || helperText}
                        error={inputError || props.errors[props.field.key]?.message!==undefined || isThreshold}
                        label={label}
                    />
                </Box>
            </ClickAwayListener>
        );
    };

    /**
     * getOptionLabel
     * @param {any} option
     * @return {string}
     */
    const getOptionLabel=(option:any):string => {
        if (typeof option==="string") return option;
        let optionLabel = "";
        Object.keys(option).forEach((key, i, arr) => {
            optionLabel += `${option[key]}`;
            if (arr[i + 1]) optionLabel += OPTION_SEPARATOR;
        });
        return optionLabel;
    };

    // /**
    //  * popperComponent
    //  * @note: This is no longer active. But keeping for potential future use.
    //  * @param {any} param
    //  * @return {React.ReactElement}
    //  */
    // const popperComponent=(param:any):React.ReactElement => {
    //     const newParam={...param};
    //     delete newParam.disablePortal;
    //     delete newParam.anchorEl;
    //     return (
    //         <Popper
    //             {...param}
    //         >
    //             <Paper sx={{marginBottom: "-8px"}}>
    //                 <Box sx={{backgroundColor: "white"}}>
    //                     <List dense sx={{width: "100%", padding: "0px"}}>
    //                         <ListItem disablePadding>
    //                             <ListItemButton sx={{backgroundColor: "white", padding: "6px 16px"}} onMouseDown={(e) => e.preventDefault()} onClick={() => { setCheckAll((prev) => !prev); }}>
    //                                 <Checkbox
    //                                     icon={<CheckBoxOutlineBlank fontSize="small" />}
    //                                     checkedIcon={<CheckBox fontSize="small" />}
    //                                     checked={checkAll}
    //                                     sx={{marginRight: "8px"}}
    //                                 />
    //                                 <ListItemText primaryTypographyProps={{variant: "body1"}} id="select-all" primary={props.field.autocompleteOptions?.selectAllLabel || "Select All"} />
    //                             </ListItemButton>
    //                         </ListItem>
    //                     </List>
    //                 </Box>
    //             </Paper>
    //             <Box {...newParam} />
    //         </Popper>
    //     );
    // };

    /**
     * handleSelectorChange
     * @param {any} Selector
     * @return {string}
     */
    const handleSelectorChange=(option:Selector):void => {
        setSelector(option);
        setInternalHelperText("");
        setInputError(false);
    };

    /**
     * resolveSelection
     * @return {string}
     */
    const resolveSelection=(data:any):any => {
        const {options, selectAllLabel}:AutocompleteOptions=props.field.autocompleteOptions as AutocompleteOptions;
        // If the data includes "Select All":
        if (Array.isArray(data) && data.includes(selectAllLabel)) {
            // Return all the options. Ie; everything "selected".
            if (!checkAll) {
                setCheckAll(true);
                return props.field.autocompleteOptions?.options;
            }
            // If the data includes "Select All", but the total selection
            // is less then ALL, splice "Select All" and return the remaining selection.
            if (options?.length && data.length < options.length) {
                const foundIdx = data.findIndex((el:string) => el === selectAllLabel);
                data.splice(foundIdx, 1);
                setCheckAll(false);
                return data;
            }
        }
        // If !checkAll, and the length of selections equals total options,
        // manually set checkAll.
        if (!checkAll && options && selectAllLabel && (options.length - 1 === data.length)) {
            setCheckAll(true);
            return props.field.autocompleteOptions?.options;
        }
        // If the data does not include "Select All", but "checkAll" is true:
        // reset, and return an empty array.
        if (checkAll) { setCheckAll(false); return []; }
        setCheckAll(false);
        return data;
    };

    /**
     * resolveOptions
     * @return {string[]}
     */
    const resolveOptions=():string[] => {
        const {options, selectAllLabel}:AutocompleteOptions=props.field.autocompleteOptions as AutocompleteOptions;
        if (selectAllLabel && !options.includes(selectAllLabel)) {
            options.unshift(selectAllLabel);
            return options;
        }
        return isThreshold?[]:options;
    };

    /**
     * handleOpen
     * @return {void}
     */
    const handleOpen=():void => {
        if (!props.onItemSelection) {
            setOpen(true);
        }
    };

    return (
        <Controller
            name={props.field.key}
            defaultValue={props.field.autocompleteOptions.multiple ? [] : null}
            control={props.control}
            render={
                ({field: {onChange, value}}) => (
                    <FormGroup
                        className={props.field.autocompleteOptions?.selector && "selector"}
                        sx={{
                            flexWrap: "nowrap",
                            "&.selector .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                borderTopLeftRadius: "0",
                                borderBottomLeftRadius: "0",
                            },
                        }}
                        row
                    >
                        { props.field.autocompleteOptions?.selector
                        && <SelectorButton disabled={props.field.autocompleteOptions?.disabled} onChange={handleSelectorChange} options={props.field.autocompleteOptions?.selector} />}
                        <AutocompleteBase
                            disableCloseOnSelect={disableCloseOnSelect}
                            onChange={(_:React.SyntheticEvent, data:any, reason:string) => {
                                if (reason === "selectOption" && props.onItemSelection) {
                                    setAsyncTextFieldFocus(false);
                                    props.onItemSelection(data);
                                    return "";
                                }
                                return onChange(resolveSelection(data));
                            }}
                            onInputChange={() => setAsyncTextFieldFocus(true)}
                            value={value}
                            onOpen={handleOpen}
                            onClose={() => setOpen(false)}
                            open={isThreshold?false:open}
                            options={resolveOptions()}
                            disabled={props.field.autocompleteOptions?.disabled||false}
                            readOnly={props.field.autocompleteOptions?.readOnly||false}
                            renderInput={renderInput}
                            loading={loading}
                            getOptionLabel={getOptionLabel}
                            renderOption={renderOption}
                            isOptionEqualToValue={(option:string, v:string) => (lo.isEqual(option, v))}
                            groupBy={() => ""} // overridden by renderGroup
                            renderGroup={renderGroup}
                            multiple={props.field.autocompleteOptions?.multiple}
                            limitTags={1}
                            renderTags={(tagValue, getTagProps) => {
                                let limitTagText;
                                if (tagValue.length > 1 && tagValue[0] !== props.field.autocompleteOptions?.selectAllLabel) {
                                    limitTagText = `+${tagValue.length - 1}`;
                                }
                                return (
                                    <>
                                        <Chip {...getTagProps({index: 0})} key={0} label={tagValue[0]} />
                                        <Typography>{limitTagText}</Typography>
                                    </>
                                );
                            }}
                            sx={{
                                width: "100%",
                                ".MuiAutocomplete-inputRoot": {borderRadius: DROPDOWN_BORDER_RADIUS},
                                ".MuiChip-root": {
                                    backgroundColor: theme.palette.primary.main,
                                    color: "white",
                                    fontSize: "13px",
                                },
                                ".MuiChip-root svg": {
                                    color: "white",
                                    opacity: "0.5",
                                    height: "18px",
                                },
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.palette.primary.main,
                                },
                            }}
                        />
                    </FormGroup>
                )
            }
        />
    );
}

export {
    Button,
    ControlledAutocomplete,
};
