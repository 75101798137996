import React from "react";
import {FieldErrors, FieldValues, UseFormReturn} from "react-hook-form";
import {Box, Grid} from "@mui/material";
import _ from "lodash";
import * as yup from "yup";
import {useNavigate} from "react-router-dom";
import {Search as SearchIcon} from "@mui/icons-material";
import {Button, Field, ControlledAutocomplete} from "../generics/inputs";
import {APP_PATHS, REPORTING_LEVEL, TIMESCALE, YEAR_TYPE} from "../../config";

/**
 * validateId
 * "id" traits [positive, integer]
 * @param {any} id
 * @return {Promise<boolean>}
 */
const validateId=async (id:any):Promise<boolean> => (
    yup
        .number()
        .integer()
        .moreThan(-1)
        .typeError("Please provide a valid BDBID number.")
        .validate(id)
        .then((value:any) => true)
        .catch((err:any) => err));

/**
 * validateMeter
 * @param {any} meter
 * @return {Promise<yup.ValidationError|boolean>}
 */
const validateMeter=async (meter:any):Promise<yup.ValidationError|boolean> => (
    yup
        .string()
        .test(
            "size-test",
            "",
            (value:any, testContext:yup.TestContext):yup.ValidationError|boolean => {
                if (value.length<3) return testContext.createError({message: "You must enter at least three characters to search by Account Meter.", path: "meter"});
                return true;
            },
        )
        .validate(meter)
        .then((value:any) => true)
        .catch((err:any) => err));

/**
 * validateAddress
 * "address"
 * @param {any} address
 * @return {Promise<boolean>}
 */
const validateAddress=async (address:any):Promise<boolean> => (
    yup
        .string()
        .typeError("Please provide a valid address.")
        .validate(address)
        .then((value:any) => true)
        .catch((err:any) => false));

export const Fields:Field[] = [
    {
        key: "reporting_level",
        label: "Reporting Level",
        yup: yup.string().required("Required"),
        type: "autocomplete",
        autocompleteOptions: {
            options: REPORTING_LEVEL.map((level) => level.label),
            readOnly: false,
            disabled: false,
        },
    },
    {
        key: "meter",
        label: "Account Meter",
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
            readOnly: false,
            disabled: false,
            helperText: "Hit 'Enter' to search by Account-Meter",
            endAdornment: {
                icon: <SearchIcon />,
                onClick: async (args:React.MouseEvent|React.KeyboardEvent) => {},
            },
            optionsHeader: [
                {label: "Account Meter", key: "account_meter", width: 50},
                {label: "Meter Type", key: "meter_type", width: 50},
            ],
            inputValidator: [
                {
                    key: "meter",
                    yup: validateMeter,
                },
            ],
        },
    },
    {
        key: "property",
        label: "Search BDBID or Address", // this will not be shown.
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
            readOnly: false,
            disabled: false,
            helperText: "Hit 'Enter' to search by BDBID or Address",
            selector: [{key: "bdbid", label: "BDBID"}, {key: "address", label: "Address"}],
            endAdornment: {
                icon: <SearchIcon />,
                onClick: async (args:React.MouseEvent|React.KeyboardEvent) => {},
            },
            optionsHeader: [
                {label: "Property Name", key: "property_name", width: 45},
                {label: "BDBID", key: "bdbid", width: 15},
                {label: "Address", key: "address", width: 45},
            ],
            inputValidator: [
                {
                    key: "bdbid",
                    yup: validateId,
                },
                {
                    key: "address",
                    yup: validateAddress,
                },
            ],
        },
    },
    {
        key: "agency",
        label: "Agency",
        yup: yup.array().min(1, "Select an agency").required("Required"),
        type: "autocomplete",
        autocompleteOptions: {
            multiple: true,
            selectAllLabel: "All Agencies",
            options: [],
            readOnly: false,
            disabled: false,
        },
    },
    {
        key: "timescale",
        label: "Timescale",
        yup: yup.string().required("Required"),
        type: "autocomplete",
        autocompleteOptions: {
            options: TIMESCALE.map((timescale) => timescale.label),
            readOnly: false,
            disabled: false,
        },
    },
    {
        key: "year_type",
        label: "Year Type",
        yup: yup.string().required("Required"),
        type: "autocomplete",
        autocompleteOptions: {
            options: YEAR_TYPE.map((type) => type.label),
            readOnly: false,
            disabled: false,
        },
    },
];

interface Props{
    onSubmit:{callback: (values:FieldValues) => void, disabled: boolean}
    onError:(error:FieldErrors) => void,
    onItemSelection:(data:any) => void
    formReturn:UseFormReturn<FieldValues>
    fields:Field[],
    dataGrid:React.ReactElement|null
    disabled:boolean
    loading?:boolean
}

/**
 * BasicExport
 * @param {Props} props
 * @return {React.ReactElement}
 */
function BasicExport(props:Props):React.ReactElement {
    const navigate=useNavigate();

    /**
     * findField
     * @params {string} key
     * @return {Field}
     */
    const findField=(key:string):any => (
        props.fields.find((x) => x.key === key)
    );

    /**
     * renderField
     * @params {string} field
     * @params {number} width?
     * @params {any} onItemSelection?
     * @return {React.ReactElement}
     */
    const renderAutocomplete=(field:string, width?:number, onItemSelection?:any):React.ReactElement => (
        <Grid key={findField(field).key} item xs={width || 3}>
            <ControlledAutocomplete onItemSelection={onItemSelection} field={findField(field)} control={props.formReturn.control} loading={props.loading} errors={props.formReturn.formState.errors} />
        </Grid>
    );

    return (
        <form onSubmit={props.formReturn.handleSubmit(props.onSubmit.callback, props.onError)}>
            <Box sx={{backgroundColor: "white", borderRadius: "20px", padding: "48px", marginBottom: "32px"}}>
                <Grid container>
                    <Grid container columnSpacing={3} paddingTop={6}>
                        { props.fields.map((x) => (
                            (x.key === "reporting_level" && renderAutocomplete("reporting_level", 2))
                            || (x.key === "meter" && renderAutocomplete("meter", 6, props.onItemSelection))
                            || (x.key === "property" && renderAutocomplete("property", 6, props.onItemSelection))
                            || (x.key === "agency" && renderAutocomplete("agency", 6))
                            || (x.key === "timescale" && renderAutocomplete("timescale", 2))
                            || (x.key === "year_type" && renderAutocomplete("year_type", 2))
                        ))}
                    </Grid>
                </Grid>
                <Box sx={{margin: (props.dataGrid ? "80px 0px" : "0px")}}>{ props.dataGrid }</Box>
                <Box sx={{display: "flex", marginTop: "20px", justifyContent: "center"}}>
                    {props.disabled
                        ?(
                            <Button
                                sx={{width: "auto"}}
                                label="Start A new Search"
                                onClick={(args:React.MouseEvent) => { navigate(APP_PATHS.BASIC_DATA_EXPORT, {state: {from: window.location.pathname}}); }}
                                variant="contained"
                                type="button"
                            />
                        )
                        :(
                            <Button
                                sx={{width: "auto", textTransform: "capitalize", borderRadius: "8px"}}
                                label="Download"
                                size="large"
                                disabled={props.onSubmit.disabled}
                                variant="contained"
                                type="submit"
                            />
                        )}
                </Box>
            </Box>
        </form>
    );
}

export default BasicExport;
